import React, { useState, useEffect }  from "react";
import Header from "../functions/header";
import Footer from "../functions/footer";
import stairs from './img/Eco3.webp'
import machines from './img/Eco5.webp'
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { FaCalendarCheck } from "react-icons/fa6";
import axios from 'axios';

const Contact = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const [embedCode, setEmbedCode] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    comment: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:9000/api/send', formData);
      alert('Email sent successfully');
      setFormData({ name: '', email: '', phone: '', subject: '', comment: '' });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };


  

  useEffect(() => {
    // Define the URL of your backend endpoint
    const apiUrl = 'http://localhost:9000/api/location'; // Assuming your backend server is running on localhost:9000

    // Make a GET request to the backend endpoint
    fetch(apiUrl)
      .then(response => response.text())
      .then(data => {
        setEmbedCode(data);
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error
      });
  }, []);

  const [isClicked, setIsClicked] = useState(false);
  
  const handleClick = () => {
    setIsClicked(true);
  };

  const handleBlur = () => {
    setIsClicked(false);
  };

  return(
    <div className="contact-page-all">
      <div>
        <Header />
      </div>
      <div className="contact-banner">
        <img src={stairs} alt="" className="contact-img-banner"/>
      </div>
      <div className="contact-div-1">
        <div className="contact-serving">
          <h2>
            Serving all of Benton County Oregon
          </h2>
          <p>
            Are you ready to experience the cleanest laundry experience you've ever had?
            Our Corvallis Eco Laundry Crew is ready to welcome you every day from 8 AM to 9 PM (last wash).
            Let us show you how clean doing laundry can really be!
          </p>
          <img src={machines} alt="" />
        </div>
        <div className="contact-details">
          <h2>
            Questions? Answers are only a call or email away!
          </h2>
          <div >
            <FaPhone />
            <a 
              href="tel:+15417521251" 
              className="contact-detail-contents">
              (541) 752-1251
            </a>
          </div>
          <div>
            <FaLocationDot />
            <a 
              href="https://www.google.com/maps/place/825+W+7th+Ave,+Eugene,+OR+97402/@44.0524444,-123.1077721,17z/data=!3m1!4b1!4m6!3m5!1s0x54c11e7202f3c14d:0xc9ed5985733436d!8m2!3d44.0524406!4d-123.1051972!16s%2Fg%2F11ckqm5g56?entry=ttu" 
              className="contact-detail-contents">
              908 Kings Blvd Corvallis, OR 97330
            </a>
          </div>
          <div>
            <IoIosMail />
            <a 
              href="mailto:info@cleaneugene.earth"
              className="contact-detail-contents">
              info@corvallisecolaundry.com
            </a>
          </div>
          <div className="contact-detail-times">
              <FaCalendarCheck />
            <p className="contact-detail-contents">
              7 Days a Week from: 8 AM - 9 PM for Last Wash
            </p>
          </div>
        </div>
      </div>
      <div className="email-form-field">
        <form onSubmit={handleSubmit}>
          <h2>
            Drop us a line!
          </h2>

          <div>
          <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
                onClick={handleClick}
                onBlur={handleBlur}
                required
                className={`email-input ${isClicked ? 'input-clicked' : ''}`}
              />
          </div>

          <div>
          <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                onClick={handleClick}
                onBlur={handleBlur}
                required
                className={`email-input ${isClicked ? 'input-clicked' : ''}`}
              />
          </div>

          <div>
          <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                onClick={handleClick}
                onBlur={handleBlur}
                required
                className={`email-input ${isClicked ? 'input-clicked' : ''}`}
              />
          </div>

          <div>
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone"
                value={formData.phone}
                onChange={handleChange}
                onClick={handleClick}
                onBlur={handleBlur}
                required
                className={`email-input ${isClicked ? 'input-clicked' : ''}`}
              />
              </div>

              <div>
              <input
                type="text"
                name="comment"
                placeholder="How can we help you?"
                value={formData.comment}
                onChange={handleChange}
                onClick={handleClick}
                onBlur={handleBlur}
                required
                className={`email-input ${isClicked ? 'input-clicked' : ''}`}
              />
              </div>
              <button type="submit" className="form-button">
                SEND
              </button>
        </form>
      </div>
      <div className="google-maps-api">
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Contact