import React, { useEffect } from 'react'
import Header from '../functions/header'
import Footer from '../functions/footer'
import Allimages from '../functions/img-gallery'
import photo1 from './img/Eco4.webp'


const Gallery = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='gallery-container'>
      <div>
        <Header />
      </div>
      <div className='gallery-img-container'>
        <img src={photo1} alt="" className='gallery-img'/>
      </div>
      <div>
        <Allimages />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Gallery