import React, { useEffect } from 'react'
import Header from '../functions/header'
import Footer from '../functions/footer'
import { FaUserCircle } from "react-icons/fa";

const Testimony = ( ) => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className='testimonial-container'>
      <div>
        <Header />
      </div>
      <div className='testimony-page-background'>
      <div className='testimonies'>
        <div className='chat-box-container'>
          <div className='chat-box'>
            <p className='message'>
              Really nice place! Very clean and maintained. They have someone on site there to help, which is always nice. 
              Plenty of machines and easy to use. I appreciate that they offer free soaps for customers. I felt safe and 
              comfortable here too.
            </p>
            <div className='triangle'></div>
          </div>
          <div className='user-comp'>
            <div className='user-icon'>
              <FaUserCircle />
            </div>
            <div>
              <h3>
                Courtney M.
              </h3>
              <p>
                09/04/2023
              </p>
              <a href="https://www.yelp.com/biz/corvallis-eco-laundry-corvallis?hrid=f3FnHHo9tdppxPgEW1GyIQ&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)" target="_blank" rel="noopener noreferrer">
                Visit Review
              </a>
            </div>
          </div>
        </div>

        <div className='chat-box-container'>
          <div className='chat-box'>
            <p className='message'>
              The attendant was wonderful. Helped us through every step as we are laundromat newbies. Our power in 
              Springfield was out for over a week and laundry couldn’t wait. This helped with my stress management 
              :)
            </p>
            <div className='triangle'></div>
          </div>
          <div className='user-comp'>
            <div className='user-icon'>
              <FaUserCircle />
            </div>
            <div>
              <h3>
                Holley B.
              </h3>
              <p>
                02/2024
              </p>
            </div>
          </div>
        </div>

        <div className='chat-box-container'>
          <div className='chat-box'>
            <p className='message'>
              Probably the cleanest laundromat I’ve ever seen.  The lady working there was incredibly nice and helpful, 
              did you know the dryer is free your first visit?  The second time I came, I forgot my detergent at home, 
              no problem-they will hook you up for free!  Prices weren’t bad and the machines were in great working 
              order so the process was really quick.
            </p>
            <div className='triangle'></div>
          </div>
          <div className='user-comp'>
            <div className='user-icon'>
              <FaUserCircle />
            </div>
            <div>
              <h3>
                Beni J.
              </h3>
              <p>
                10/2023
              </p>
            </div>
          </div>
        </div>
        
        <div className='chat-box-container'>
          <div className='chat-box'>
            <p className='message'>
              Great experience. Went to wash blankets. As others have mentioned, the attendant, who was really nice, 
              explained how everything worked, gave me free drying and soap. Plus, she paid attention and sanitized the 
              washers after people were done with them. Very clean laundromat, including restroom. Efficient new industrial 
              machines, washer and dryer took about 30 minutes each. Will definitely use again.
            </p>
            <div className='triangle'></div>
          </div>
          <div className='user-comp'>
            <div className='user-icon'>
              <FaUserCircle />
            </div>
            <div>
              <h3>
                Debra F.
              </h3>
              <p>
                5/2023
              </p>
            </div>
          </div>
        </div>

        <div className='chat-box-container'>
          <div className='chat-box'>
            <p className='message'>
              clean crisp and quiet. lots of washers and dryers. walking distance to the co-op and the asian supermarket. 
              quarter machine and credit cards.
            </p>
            <div className='triangle'></div>
          </div>
          <div className='user-comp'>
            <div className='user-icon'>
              <FaUserCircle />
            </div>
            <div>
              <h3>
                Paia C.
              </h3>
              <p>
                10/21/2023
              </p>
              <a href="https://www.yelp.com/biz/corvallis-eco-laundry-corvallis?hrid=Uys6kT_GqwOx2TH10uUwug&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)" target="_blank" rel="noopener noreferrer">Visit Review</a>
            </div>
          </div>
        </div>

        <div className='chat-box-container'>
          <div className='chat-box'>
            <p className='message'>
              The girl that works there was super helpful to me. Being this was my first time back in a couple years it 
              shows some good improvements. The place is very clean and well run. The washer and dryer was able to clean 
              my large down comforter easily.
            </p>
            <div className='triangle'></div>
          </div>
          <div className='user-comp'>
            <div className='user-icon'>
              <FaUserCircle />
            </div>
            <div>
              <h3>
                Gina G.
              </h3>
              <p>
                03/18/2022
              </p>
              <a href="https://www.yelp.com/biz/corvallis-eco-laundry-corvallis?hrid=w4q9c84eNJ5GBeAUQwZvgg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)" target="_blank" rel="noopener noreferrer">Visit Review</a>
            </div>
          </div>
        </div>

      </div>
      <p>
        Testimonials attained through Google reviews, Yelp, and In-store.
      </p>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Testimony