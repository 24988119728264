import React, { useEffect } from 'react'
import Header from '../functions/header'
import Photo1 from './img/Eco4.webp'
import Shirt from './img/shirt.webp'
import Wash from './img/washing_machine.png'
import Wool from './img/wool_dryer.png'
import Soap from './img/laundry_liquid.png'
import { SlArrowRight } from "react-icons/sl";
import {Link} from 'react-router-dom'
import { BsChevronRight } from "react-icons/bs";
import Seats from './img/Eco10.webp'
import Machines from './img/Eco7.webp'
import divider from './img/divider-line.webp'
import bracketleft from './img/bracket-left.webp'
import bracketright from './img/bracket-right.webp'
import bracketbottom from './img/bracket-bottom.webp'
import Upperveiw from './img/Eco8.webp'
import Lowerveiw from './img/Eco3.webp'
import Footer from '../functions/footer'


const Home = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return(
    <div id='home-container'>
      <div>
        <Header/>
      </div>
      <div >
        <img src={Photo1} alt="Corvallis laundromat" className='top-img-1'/>
      </div>
      <div className='welcome'>
        <p>
          Welcome to Corvallis Eco Laundry!
        </p>
        <div>
        <a href="/Contact" className="buttons-welcome">DIRECTIONS & HOURS</a>
        <a href="/clean-commitment" className="buttons-welcome">CLEAN COMMITMENT</a>
        <a href="/Gallery" className="buttons-welcome">GALLERY</a>
        </div>
      </div>
      <div className='shirt-container '>
        <div className='bracket-top'>
          <img src={bracketleft} alt="" className='bracket'/>
          <img src={Shirt} alt="" className='shirt-icon'/>
          <img src={bracketright} alt="" className='bracket'/>
        </div>
        <h2>
          Corvallis Eco Laundry is Committed to our Customers, Clean Clothes and a Clean Community
        </h2>
        <img src={bracketbottom} alt="" className='bracket-bottom'/>
      </div>
      <div className='commitment-container'>
        <div className='commitment-style'>
          <div>
            <h2>
              Corvallis Eco Laundry is Committed!
            </h2>
              
          </div>
          <div className='commitment-link'>
            <div>
            <a href="/clean-commitment">
              Read our full Corvallis Eco Laundry Commitment here 
            </a>
            </div>
            <div className='commitment-arrow'>
              <SlArrowRight />
            </div>
          </div>
        </div>
        <div className='divider-line'>
          <img src={divider} alt="" className='divider-img'/>
        </div>
        <div className='commitment-img-container'>
          <Link to='/laundry_edu/how-much-soap' className='commitment-info'>
            <div className='img-box'>
              <img src={Wool} alt="" className='img-fluid commitment-img'/>
              <div className='home-img-links'>
                <p className='img-p'>
                  COMPLIMENTARY WOOL DRYER BALLS
                </p>
                <div className='link-arrows'>
                  <BsChevronRight />
                  <BsChevronRight />  
                  <BsChevronRight />
                </div>    
              </div>
            </div>
          </Link>
          <Link to='/laundry_edu/charlies-soap' className='commitment-info'>
            <div className='img-box'>
              <img src={Soap} alt="" className='img-fluid commitment-img'/>
              <div className='home-img-links'>
                <p className='img-p'>
                  COMPLIMENTARY CHARLIE'S SOAP
                </p>
                <div className='link-arrows'>
                  <BsChevronRight />
                  <BsChevronRight />  
                  <BsChevronRight />
                </div>    
              </div>
            </div>
          </Link>
          <Link to='/laundry_edu/sanitize-feature' className='commitment-info'>
            <div className='img-box'>
              <img src={Wash} alt="" className='img-fluid commitment-img'/>
              <div className='home-img-links'>
                <p className='img-p'>
                  COMPLIMENTARY SANITIZING
                </p>
                <div className='link-arrows'>
                  <BsChevronRight />
                  <BsChevronRight />  
                  <BsChevronRight />
                </div>    
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className='payment-container'>
        <div className='wash-payment'>
          <div>
            <img src={Machines} alt="" className='machines-img'/>
          </div>
          <div className='payment-text'>
            <p>
              Pay with Quarters, Debit, Credit and Loyalty Pay.
            </p>
          </div>
        </div>
        <div className='what-we-offer'>
          <p>
            Welcome to Corvallis Eco Laundry!  We invite you into our peaceful, 
            clean space to enjoy an entirely new way of tackling 
            your laundry day.  Corvallis Eco Laundry is fully attended daily.  
            We offer restrooms, wifi and a spacious area 
            to utilize while you wait the short 45 min for your wash 
            AND dry to complete. We look forward to meeting you!
          </p>
        </div>
        <div className='save'>
          <div>
            <img src={Seats} alt="" className='upper-seating'/>
          </div>
          <div className='save-text'>
            <p>
              Save an hour or more on laundry day today!
            </p>
          </div>
        </div>
      </div>
      <div className='loyalty-program'>
        <h2>
          Join our Loyalty Program!
        </h2>
        <p>
          Join our loyalty program and get updates on events and 
          promotions at CORVALLIS ECO LAUNDRY! After you join, your re-loadable 
          loyalty card is our gift to you.  Trade your cash or coins 
          for a card that allows you to budget for the month, earn 
          added bonuses for free laundry and more!
        </p>
        <div className='img-container'>
        <img src={Upperveiw} alt="" className='img-fluid display-img'/>
        <img src={Lowerveiw} alt="" className='img-fluid display-img-2'/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home
