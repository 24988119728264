import React from "react"
import Logo from './img/CEL_logo.webp'
import { Link } from 'react-router-dom';

const Header = () => {

  return(
    <div className="header-container">
      <div>
        <img src={Logo} alt="logo" className="header-logo"/>
      </div>
      <div className="header-info-box">
        <div className="header-info">
          <p>
            Days: Open 7 Days a Week | Hours: 8am - 9pm Last Wash 
          </p>
          <p className="header-contacts">
            | Phone: <a href="tel:+15417521251" className="header-contact">(541) 752-1251</a>
          </p>

        </div>  
        <div className="header-buttons">
          <Link to='/'>
          <a href="/" className="buttons">Home</a>
          </Link>
          <Link to='/clean-commitment'>
          <a href="/clean-commitment" className="buttons">Clean Commitment</a>
          </Link>
          <Link to="/testimonials">
          <a href="/testimonials" className="buttons">Testimonials</a>
          </Link>
          <Link to='/Contact'>
          <a href="/Contact" className="button-info">Contact Us</a>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Header
