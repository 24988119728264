import React, { useEffect } from "react";
import Header from "../functions/header";
import Footer from "../functions/footer";
import soap from './img/laundry_liquid.png'

const Lecs = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        <Header />
        <div className="lecs-sub-header">
          <h1>
            Complimentary Charlie’s Soap
          </h1>
        </div>
      </div>
      <div className="lecs-page-container">
        <img src={soap} alt="" />
        <p>
          Corvallis Eco Laundry is committed to the environment. Our wastewater treatment facilities 
          cannot remove carcinogens such as Dioxane; a by-product created from the 
          vast majority of laundry soap.  Our washers, engineered for hospitals, are 
          not compatible with residential soaps.
        </p>
        <p>
          As a sign of our dedication to the environment, and to your clothing 
          investment and health, <b>Complimentary Charlie’s detergent is our 
          commitment with every wash.</b> 
        </p>
        <p>
          Charlie’s has been third-party tested to be of the highest quality and 
          safest laundry detergent on the market with the most superior results. Charlies is 
          Biodegradable, hypoallergenic and petrochemical and Dioxane free. Alternative safe 
          laundry detergent options are available from a CEL Crew member, along with a list of 
          third-party tested safe options you can purchase at most grocery stores.
        </p>
        <p>
          We will always help you find a compatible soap that meets your expectations that is safe 
          for both you and the environment. That is our Corvallis Eco Laundry Commitment to you.
        </p>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Lecs