import React, { useState, useEffect } from 'react'
import Header from '../functions/header'
import Footer from '../functions/footer'
import axios from 'axios'

const Career = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    job_exp: '',
    hobbies: '',
    skills: '',
    available: '',
    reason: '',
    resume: null // Store uploaded resume file
  });

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('job_exp', formData.job_exp);
    formDataToSend.append('hobbies', formData.hobbies);
    formDataToSend.append('skills', formData.skills);
    formDataToSend.append('available', formData.available);
    formDataToSend.append('reason', formData.reason);
    formDataToSend.append('resume', formData.resume);

    try {
      await axios.post('http://localhost:9000/api/apply', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Email sent successfully');
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: '',
        job_exp: '',
        hobbies: '',
        skills: '',
        available: '',
        reason: '',
        resume: null // Reset uploaded resume file
      });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };
  

  return (
    <div className='career-page-container'>
      <div>
        <Header />
        <div className='apply-now'>
          <h1>
            Apply Now!
          </h1>
        </div>
      </div>
      <div className='careers-container'>
        <form onSubmit={handleSubmit} className='career-form'>

          <div>
            <input type="text" 
            name='name'
            placeholder='Full Name' 
            value={formData.name}
            onChange={handleChange}
            required
            />
          </div>

          <div>
            <input type="tel" 
            name='phone'
            placeholder='Phone Number' 
            value={formData.phone}
            onChange={handleChange}
            required
            />
          </div>

          <div>
            <input type="email" 
            name='email'
            placeholder='Email Adress' 
            value={formData.email}
            onChange={handleChange}
            required
            />
          </div>

          <div>
            <input type="text" 
            name='address'
            placeholder='Physical Address' 
            value={formData.address}
            onChange={handleChange}
            required
            />
          </div>

          <div>
            <textarea 
            name='job_exp'
            type="text" 
            id="" 
            cols="50" 
            rows="10" 
            placeholder='Previous jobs for the past 5 years. (Organization, Dates, Job duties.)'
            value={formData.job_exp}
            onChange={handleChange}
            required>
            </textarea>
          </div>

          <div>
            <textarea 
            name="hobbies" 
            id="" 
            cols="50" 
            rows="10"
            placeholder='List your hobbies or activities'
            value={formData.hobbies}
            onChange={handleChange}
            required>

            </textarea>
          </div>
          
          <div>
            <textarea 
            name="skills" 
            id="" 
            cols="50" 
            rows="10"
            placeholder='List your skills or certificates'
            value={formData.skills}
            onChange={handleChange}
            required>
            </textarea>
          </div>

          <div>
            <textarea 
            name="available" 
            id="" 
            cols="50" 
            rows="10"
            placeholder='Days and hours available'
            value={formData.available}
            onChange={handleChange}
            required>

            </textarea>
          </div>

          <div>
            <textarea 
            name="reason" 
            id="" 
            cols="50" 
            rows="10"
            placeholder='Why do you believe you are a good fit for this job?'
            value={formData.reason}
            onChange={handleChange}
            required>
            </textarea>
          </div>

          <div className='resume-file'>
            <p>
              Upload a Resume:
            </p>
            <input 
            type="file" 
            name='resume'
            accept=".pdf, .doc, .docx"
            onChange={handleFileChange}
            required/>
          </div>

          <button type='submit'>Submit</button>
        </form>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Career