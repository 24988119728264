import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => {

  return(
    <div className='footer-container'>
      <h2>
        Sustainable Laundry Solutions
      </h2>
      <ul>
        <li>
          <Link to='/clean-commitment' className='footer-links'>
            Clean Commitment
          </Link>
        </li>
        <li>
          <Link to="/Contact" className='footer-links'>
            Contact Us
          </Link>
        </li>
        <li>
          <Link to="/gallery" className='footer-links'>
            Gallery
          </Link>
        </li>
        <li>
          <Link to="/careers" className='footer-links'>
            Careers
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Footer