import React, { useEffect } from 'react'
import Footer from '../functions/footer'
import Header from '../functions/header'
import wool from './img/wool_dryer.png'

const Lehms = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className='lehms-container'>
      <div>
        <Header />
        <div className='lehms-sub-header'>
          <h1>
            Complimentary Wool Dryer Balls
          </h1>
        </div>
      </div>
      <div className='lehms-page-container'>
        <img src={wool} alt="" className='img-fluid'/>
        <p>
          Enjoy complimentary use of our wool dryer balls with each dry.  
          These naturally reduce static, soften clothes and speed up dry 
          time. We offer a variety of essential oil scents added to gently 
          scent your clothing without any nasty residue or petrochemicals.
        </p>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Lehms